/** Third-party libraries */
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Input, List, theme } from "antd";
import { FC, useMemo, useState } from "react";

/** Project components */
import { StringUtility } from "@/components/common/utilities";
import { useAuthenticationContext } from "../authentication";
import { useUsersQuery } from "../graphql";
import { useDebouncedValue } from "../hooks/use-debounce";

const { useToken } = theme;

/** Types for the CommunicationLogAgentAssign component. */
type CommunicationLogAgentChipProps = {
  /** Twilio call ID. */
  callSid: string;
  /** User/agent that the call is currently assigned to. */
  assignedUser?: {
    /** ID of the user/agent that the call is assigned to. */
    id: string;
    /** Name of the user/agent that the call is assigned to. */
    name: string;
  };
  /** Flag for enabling the dropdown menu. Defaults to `true`. */
  enabled?: boolean;
  /** Flag for loading state. */
  loading?: boolean;
  /** Callback function for assigning a call. */
  onAssignCall?: (params: { callSid: string; userId: string }) => void;
  /** Callback function for unassigning a call. */
  onUnassignCall?: (params: { callSid: string }) => void;
};

/**
 * Component for displaying the agent chip in the communication log card.
 * Also used for assigning/unassigning an agent to a call.
 */
const CommunicationLogAgentChip: FC<CommunicationLogAgentChipProps> = (
  props,
) => {
  const { token } = useToken();
  /** Logged on user details. */
  const { user } = useAuthenticationContext();

  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedValue] = useDebouncedValue({
    value: searchText,
    ms: 300,
  });

  const { data: usersData, loading: usersLoading } = useUsersQuery({
    variables: { filter: { name: debouncedSearchText } },
  });

  /** List of users/agents that are not the logged on user. */
  const agentList = useMemo(() => {
    if (!usersData?.users) return [];

    return usersData.users.filter((_user) => _user.id !== user?.id);
  }, [user, usersData]);

  /** Display the agent chip only if it's disabled. */
  if (!props.enabled) {
    return <AgentChip name={props.assignedUser?.name} />;
  }

  return (
    <Dropdown
      trigger={["click"]}
      placement="bottom"
      dropdownRender={(menu) => (
        <div
          style={{
            backgroundColor: token.colorBgElevated,
            borderRadius: token.borderRadiusLG,
            boxShadow: token.boxShadowSecondary,
            width: 300,
          }}
          className="py-1"
        >
          {/* Search bar */}
          <div className="px-4 py-2">
            <Input.Search
              placeholder="Search"
              onSearch={setSearchText}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              allowClear
              onClear={() => setDebouncedValue("")}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          {/* List of users. Made list scrollable with fixed height. */}
          <div className="flex max-h-60 flex-col overflow-auto">
            {/* Assign to self */}
            <Button
              type="text"
              className={`!rounded-none px-4 py-2 font-semibold text-tpl-navy ${props.assignedUser?.id === user?.id ? "!bg-sky-100" : ""}`}
              onClick={(e) => {
                props.onAssignCall?.({
                  callSid: props.callSid,
                  userId: user?.id!,
                });
                e.stopPropagation();
              }}
            >
              <div className="w-full text-left">Assign to Self</div>
            </Button>
            {/* Remove assignment */}
            {props.assignedUser?.id && (
              <Button
                type="text"
                className="!rounded-none font-semibold text-tpl-navy"
                onClick={(e) => {
                  props.onUnassignCall?.({ callSid: props.callSid });
                  e.stopPropagation();
                }}
              >
                <div className="w-full text-left">Remove Assignment</div>
              </Button>
            )}
            {/* List of users/agents that are not the logged on user. */}
            <List
              loading={usersLoading}
              dataSource={agentList}
              renderItem={(item) => (
                <Button
                  key={item.id}
                  type="text"
                  className={`w-full !rounded-none px-4 py-2 font-semibold text-tpl-navy hover:bg-sky-100 ${props.assignedUser?.id === item.id ? "!bg-sky-100" : ""}`}
                  onClick={(e) => {
                    props.onAssignCall?.({
                      callSid: props.callSid,
                      userId: item.id,
                    });
                    e.stopPropagation();
                  }}
                >
                  <AgentChip name={item.profile.fullName} size="large" />
                </Button>
              )}
            />
          </div>
        </div>
      )}
    >
      <Button
        type="link"
        size="small"
        className="!p-0"
        loading={props.loading}
        onClick={(e) => e.stopPropagation()}
      >
        <AgentChip name={props.assignedUser?.name} />
      </Button>
    </Dropdown>
  );
};

export default CommunicationLogAgentChip;

// ===========================================================================
// ===========================================================================
// AgentChip component
// ===========================================================================
// ===========================================================================

/**
 * Properties of the AgentChip component.
 */
type AgentChipProps = {
  /** The name of the agent. Defaults to `Unassigned`. */
  name?: string;
  /** The size of the agent chip. */
  size?: "small" | "large";
};

/**
 * Displays the avatar and name of an agent.
 */
function AgentChip(props: AgentChipProps) {
  const size = props.size || "small";

  const textSize = size === "small" ? "text-xs" : "text-sm";
  const avatarSize = size === "small" ? 20 : 24;

  return (
    <div
      className={`flex h-6 w-full items-center text-tpl-navy ${size === "small" ? "gap-1" : "gap-2"}`}
    >
      {props.name ? (
        <Avatar size={avatarSize} className="!bg-neutral-grey">
          <div className={`font-semibold leading-5 !text-tpl-navy ${textSize}`}>
            {StringUtility.getInitials({ input: props.name })}
          </div>
        </Avatar>
      ) : (
        <div className="flex h-5 w-5 items-center justify-center rounded-full !bg-neutral-grey">
          <UserOutlined size={avatarSize} />
        </div>
      )}
      <div
        className={`max-w-44 overflow-hidden text-ellipsis text-nowrap font-semibold ${textSize}`}
      >
        {props.name || "Unassigned"}
      </div>
    </div>
  );
}
