"use client";

/**
 * Third-party libraries.
 */
import { Tooltip } from "antd";
import { useCallback, useMemo } from "react";

/**
 * Project components.
 */
import { ButtonCall, ButtonCallType } from "@/components/client/button";
import {
  CommunicationDirection,
  CommunicationLogStatus,
  useCommunicationLogContext,
} from "@/components/client/communication-log";
import { CommunicationLog } from "@/components/client/communication-log/types";
import {
  useCallAssignMutation,
  useCallMissedAssignMutation,
  useCallMissedUnassignMutation,
  useCallUnassignMutation,
} from "@/components/client/graphql";
import { Icon } from "@/components/client/icon";
import { useTwilioContext } from "@/components/client/twilio";
import { ASSET_ROUTE } from "@/components/common/route";
import CommunicationLogAgentChip from "./communcation-log-agent-chip";

/**
 * Communication log card properties.
 */
export type CommunicationLogCardProps = {
  /**
   * Data to display on the card.
   */
  data: CommunicationLog;
  /**
   * Callback when the card is clicked.
   */
  onClick?: (args: {
    communicationLog: CommunicationLog;
  }) => void | Promise<void>;
  /**
   * Callback when an incoming call is accepted.
   *
   * Only available for incoming ringing calls.
   */
  onAccept?: () => void;
  /**
   * Callback when the an incoming call is rejected or an outbound call is
   * canceled by the user.
   *
   * Available for:
   * - Incoming ringing and ongoing calls.
   * - Outgoing ringing and ongoing calls.
   */
  onHangUp?: () => void;
};

/**
 * A single entry in the communication log list.
 */
export const CommunicationLogCard = ({
  data,
  onClick,
}: CommunicationLogCardProps) => {
  const {
    callSid,
    clientName,
    clientPhoneNumber,
    direction,
    id,
    status,
    time,
    to,
    missedCount,
  } = data;

  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const {
    accept,
    hangUp,
    isAccepting,
    isHangingUp,
    communicationLogsActiveLoading,
    communicationLogsConcludedLoading,
    selectedCommunicationLog,
  } = useCommunicationLogContext();
  const { getCall, isMuted, toggleMute } = useTwilioContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * Indicates that the call accept service is loading.
   */
  const acceptingCall = data?.callSid ? isAccepting({ callSid }) : false;

  /**
   * Indicates that the hang up call service is loading.
   */
  const hangingUpCall = data?.callSid ? isHangingUp({ callSid }) : false;

  /**
   * Indicates that the call accept service is loading.
   */
  const isAcceptButtonVisible =
    status === CommunicationLogStatus.RINGING &&
    direction === CommunicationDirection.INBOUND;

  /**
   * Indicates that the call has a hang up button.
   */
  const isHangUpButtonVisible =
    (status === CommunicationLogStatus.RINGING ||
      status === CommunicationLogStatus.ONGOING) &&
    (direction === CommunicationDirection.INBOUND ||
      direction === CommunicationDirection.OUTBOUND);

  /**
   * The icon to display based on the status of the communication.
   */
  const icon = useMemo(() => {
    switch (status) {
      case CommunicationLogStatus.CANCELED:
      case CommunicationLogStatus.DECLINED:
      case CommunicationLogStatus.COMPLETED:
      case CommunicationLogStatus.WRAPPING_UP:
        return "phone-down";
      case CommunicationLogStatus.FAILED:
      case CommunicationLogStatus.MISSED:
      case CommunicationLogStatus.NO_RESPONSE:
      case CommunicationLogStatus.ONGOING:
      case CommunicationLogStatus.PENDING:
      case CommunicationLogStatus.RINGING:
        return "phone";
      default:
        throw new Error(`Unknown status: ${status}`);
    }
  }, [status]);

  /**
   * The colors to display based on the status of the communication.
   * This includes the following:
   * - Background color of the card
   * - Icon color
   * - Text color
   */
  const color = useMemo(() => {
    /**
     * Indicates that this communication log is actively selected.
     */
    const isSelected = selectedCommunicationLog?.id === id;

    switch (status) {
      case CommunicationLogStatus.PENDING:
      case CommunicationLogStatus.RINGING:
      case CommunicationLogStatus.WRAPPING_UP:
        return {
          background: isSelected ? "bg-sky-100" : "bg-sky-100",
          border:
            status === CommunicationLogStatus.RINGING
              ? "border-l-4 border-solid border-tpl-blue"
              : undefined,
          icon: "!text-tpl-navy",
          text: "",
        };
      case CommunicationLogStatus.ONGOING:
        return {
          background: "bg-semantic-green",
          border: undefined,
          icon: "!text-white",
          text: "text-white",
        };
      case CommunicationLogStatus.COMPLETED:
        return {
          background: isSelected ? "bg-sky-100" : "bg-white",
          icon: "!text-semantic-green",
          text: "",
        };
      case CommunicationLogStatus.CANCELED:
      case CommunicationLogStatus.DECLINED:
      case CommunicationLogStatus.FAILED:
      case CommunicationLogStatus.MISSED:
      case CommunicationLogStatus.NO_RESPONSE:
        return {
          background: isSelected ? "bg-sky-100" : "bg-white",
          border: undefined,
          icon: "!text-semantic-red",
          text: "",
        };
      default:
        throw new Error(`Unknown status: ${status}`);
    }
  }, [id, selectedCommunicationLog?.id, status]);

  /**
   * Active Twilio call associated with this communication log card.
   *
   * This could be null if there is no active call.
   *
   * Active call means the call is connected to the Twilio server.
   */
  const twilioCall = useMemo(
    () =>
      status === CommunicationLogStatus.ONGOING
        ? getCall({
            callSid: data.callSid,
          })
        : undefined,
    [data.callSid, getCall, status],
  );

  // ===========================================================================
  // ===========================================================================
  // Mutations
  // ===========================================================================
  // ===========================================================================

  const [assignCallMutation, { loading: assignCallMutationLoading }] =
    useCallAssignMutation();
  const [
    assignCallMissedMutation,
    { loading: assignCallMissedMutationLoading },
  ] = useCallMissedAssignMutation();

  const [unassignCallMutation, { loading: unassignCallMutationLoading }] =
    useCallUnassignMutation();
  const [
    unassignCallMissedMutation,
    { loading: unassignCallMissedMutationLoading },
  ] = useCallMissedUnassignMutation();

  /** Callback for assigning a missed call */
  const onAssignCall = async (params: { callSid: string; userId: string }) => {
    if (data.missedCount) {
      await assignCallMissedMutation({
        variables: {
          input: { callSid: data.callSid, userId: params.userId },
        },
      });
    } else {
      await assignCallMutation({
        variables: {
          input: { callSid: data.callSid, userId: params.userId },
        },
      });
    }
  };

  /** Callback for unassigning a missed call */
  const onUnassignCall = async (params: { callSid: string }) => {
    if (data.missedCount) {
      await unassignCallMissedMutation({
        variables: { input: { callSid: params.callSid } },
      });
    } else {
      await unassignCallMutation({
        variables: { input: { callSid: params.callSid } },
      });
    }
  };
  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  /**
   * Card click handler.
   */
  const handleOnClick = useCallback(async () => {
    onClick?.({ communicationLog: data });
  }, [data, onClick]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return (
    <div
      className={`flex w-full animate-slide-left cursor-pointer items-center gap-2 px-4 py-3 ${color.background} ${color.text} ${color.border}`}
      onClick={handleOnClick}
    >
      <Icon src={icon} className={`!text-2xl ${color.icon}`} />
      <div className="flex-1">
        <div className={`text-tpl-navy ${color.text} text-sm font-semibold`}>
          {clientName || clientPhoneNumber}
        </div>
        <div className="flex items-center justify-between">
          <div className={`text-gray-400 ${color.text} text-sm`}>
            {status} ({missedCount || direction})
          </div>
        </div>
        <div className="mt-1 flex w-full items-center justify-between">
          <CommunicationLogAgentChip
            callSid={data.callSid}
            assignedUser={data.user}
            /** Only enabled when the call is inbound and the status is `Missed`, `Declined`, or `Canceled`. */
            enabled={
              direction === CommunicationDirection.INBOUND &&
              (status === CommunicationLogStatus.MISSED ||
                status === CommunicationLogStatus.DECLINED ||
                status === CommunicationLogStatus.CANCELED)
            }
            loading={
              assignCallMutationLoading ||
              unassignCallMutationLoading ||
              assignCallMissedMutationLoading ||
              unassignCallMissedMutationLoading
            }
            onAssignCall={onAssignCall}
            onUnassignCall={onUnassignCall}
          />
          <div
            className={`text-nowrap text-xs text-tpl-navy-light ${
              status === CommunicationLogStatus.RINGING ||
              status === CommunicationLogStatus.ONGOING
                ? "hidden"
                : ""
            } ${color.text} `}
          >
            {time}
          </div>
        </div>
        {status === CommunicationLogStatus.RINGING && (
          <audio src={ASSET_ROUTE.AUDIO.CALL_RINGTONE} autoPlay loop />
        )}
      </div>
      <div className="flex h-full items-center justify-center gap-2">
        {status === CommunicationLogStatus.ONGOING && (
          <Tooltip
            title={
              isMuted({
                callSid: data.callSid,
              })
                ? "Unmute"
                : "Mute"
            }
            style={{
              display:
                status === CommunicationLogStatus.ONGOING ? "block" : "none",
            }}
          >
            <ButtonCall
              disabled={!twilioCall}
              icon={
                <Icon
                  src={
                    isMuted({
                      callSid: data.callSid,
                    })
                      ? "microphone-mute"
                      : "microphone"
                  }
                />
              }
              onClick={(event) => {
                event.stopPropagation();

                toggleMute({
                  callSid: data.callSid,
                });
              }}
              type={ButtonCallType.MUTE}
            />
          </Tooltip>
        )}
        {isAcceptButtonVisible && (
          <ButtonCall
            disabled={
              acceptingCall ||
              hangingUpCall ||
              communicationLogsActiveLoading ||
              communicationLogsConcludedLoading
            }
            icon={<Icon src="phone" className="!text-white" />}
            loading={acceptingCall}
            onClick={async (event) => {
              await accept({
                callSid: data.callSid,
              });
            }}
            type={ButtonCallType.ACCEPT}
          />
        )}
        {isHangUpButtonVisible && (
          <ButtonCall
            disabled={
              acceptingCall ||
              hangingUpCall ||
              communicationLogsActiveLoading ||
              communicationLogsConcludedLoading ||
              // Call is ongoing but Twilio call is missing or not yet connected.
              (status === CommunicationLogStatus.ONGOING && !twilioCall)
            }
            icon={<Icon src="phone-down" className="!text-white" />}
            loading={hangingUpCall}
            onClick={async (event) => {
              event.stopPropagation();

              await hangUp({
                callSid: data.callSid,
                direction: data.call.direction,
                status: data.call.status,
              });
            }}
            type={ButtonCallType.HANG_UP}
          />
        )}
      </div>
    </div>
  );
};
